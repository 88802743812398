.fs-6xl {
    font-size: 48px;
    line-height: 1.15;
}
@media (min-width: 992px) {
    .fs-6xl {
        font-size: calc(7.6923076923vw - 28.3076923077px);
    }
}
@media (min-width: 1200px) {
    .fs-6xl {
        font-size: 64px;
    }
}
.fs-5xl {
    font-size: 36px;
    line-height: 1.15;
}
@media (min-width: 768px) {
    .fs-5xl {
        font-size: calc(2.7777777778vw + 14.6666666667px);
    }
}
@media (min-width: 1200px) {
    .fs-5xl {
        font-size: 48px;
    }
}
.fs-4xl {
    font-size: 30px;
    line-height: 1.375;
}
@media (min-width: 768px) {
    .fs-4xl {
        font-size: calc(1.3888888889vw + 19.3333333333px);
    }
}
@media (min-width: 1200px) {
    .fs-4xl {
        font-size: 36px;
    }
}
.fs-3xl {
    font-size: 26px;
    line-height: 1.375;
}
@media (min-width: 768px) {
    .fs-3xl {
        font-size: calc(0.9259259259vw + 18.8888888889px);
    }
}
@media (min-width: 1200px) {
    .fs-3xl {
        font-size: 30px;
    }
}
.fs-2xl {
    font-size: 24px;
    line-height: 1.375;
}
@media (min-width: 768px) {
    .fs-2xl {
        font-size: calc(0vw + 24px);
    }
}
@media (min-width: 1200px) {
    .fs-2xl {
        font-size: 24px;
    }
}
.fs-xl {
    font-size: 18px;
}
@media (min-width: 768px) {
    .fs-xl {
        font-size: calc(0.462962963vw + 14.4444444444px);
    }
}
@media (min-width: 1200px) {
    .fs-xl {
        font-size: 20px;
    }
}
.fs-lg {
    font-size: 18px;
    line-height: 1.5;
}
@media (min-width: 768px) {
    .fs-lg {
        font-size: calc(0vw + 18px);
    }
}
@media (min-width: 1200px) {
    .fs-lg {
        font-size: 18px;
    }
}
.fs-md, .fs-base {
    font-size: 16px;
    line-height: 1.5;
}
@media (min-width: 768px) {
    .fs-md, .fs-base {
        font-size: calc(0vw + 16px);
    }
}
@media (min-width: 1200px) {
    .fs-md, .fs-base {
        font-size: 16px;
    }
}
.fs-sm {
    font-size: 15px;
    line-height: 1.5;
}
@media (min-width: 768px) {
    .fs-sm {
        font-size: calc(0vw + 15px);
    }
}
@media (min-width: 1200px) {
    .fs-sm {
        font-size: 15px;
    }
}
.fs-xs {
    font-size: 13px;
    line-height: 1.5;
}
@media (min-width: 768px) {
    .fs-xs {
        font-size: calc(0vw + 13px);
    }
}
@media (min-width: 1200px) {
    .fs-xs {
        font-size: 13px;
    }
}
